/**
 * Copyright 2016, Sourcepole AG.
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree.
 */

const React = require('react');

function renderHelp() {
	let outerStyle = {
			paddingTop:'10px', 
			paddingBottom:'10px',
			paddingRight:'10px',
			paddingLeft:'10px'
		};
	
    return (
    	<div style={outerStyle}>
    		<h2>Help</h2>
    		<p>To get a flood map simply follow the following steps:</p>
			<p>
				<ol>
					<li>Search for the site using the search bar at the top of the page.</li> 
					<li>Zoom into the site to see the flood extents and click on the cross to the right of the map search bar to remove the pin on the map.</li>
					<li>Click on the pencil button on the toolbar.</li>
					<li>Draw around the edge of the site, double clicking at the end to complete the polygon.</li>
					<li>Click on the print button on the toolbar</li>
					<li>Make sure the highlighted area covers the site and is big enough to see where it is. The scale of the printed image can be adjusted by changing the “Scale” setting.</li>
					<li>Enter the map title and author information.</li>
					<li>Click “Print”</li>
				</ol>
			</p>
			<p>The map will take a few seconds to be drawn and will automatically download to your computer.</p>
    	</div>
    );
}

module.exports = renderHelp;
